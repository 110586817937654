import React from 'react'
import Helmet from 'react-helmet'
import { createGlobalStyle } from 'styled-components'
import { global as designSystemGlobal } from 'rtmg-component-library';
import favicon from '../images/favicon.ico'

const { GlobalStyle } = designSystemGlobal;

const LocalStyle = createGlobalStyle`
  body {
    text-align: center;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    box-shadow: 0 0 5px 1px #ccc;
  }
  h1 {
    font-family: var(--font-serif);
    font-size: 69.2px;
    line-height: 60px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: normal;
    letter-spacing: 0px;
    -webkit-font-smoothing: antialiased;
    text-indent: 0px;
    font-style: normal;
    white-space: normal;
    text-shadow: inherit;
    display: inline;
    opacity: 1;
    box-sizing: border-box;
    transition: opacity 75ms linear 75ms;
    visibility: visible;
    @media (min-width: 768px) {
      font-size: 84px;
      line-height: 1;
    }
  }
  h2 {
    font-family: var(--font-serif);
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 0;
    text-indent: 0px;
    margin: 0;
    line-height: 36px;
    display: inline;
    opacity: 1;
    box-sizing: border-box;
    transition: opacity 75ms linear 75ms;
    visibility: visible;
    @media (min-width: 768px) {
      line-height: 56px;
    }
  }
  h3 {
    font-family: var(--font-sans);
    font-size: 16px;
    max-width: 100%;
    margin-bottom: 0;
    margin-top: 0px;
    font-weight: normal;
    letter-spacing: 3.84px;
    line-height: 28px;
    -webkit-font-smoothing: antialiased;
    text-indent: 0px;
    font-style: normal;
    white-space: normal;
    text-shadow: inherit;
    display: block;
    opacity: 1;
    box-sizing: border-box;
    transition: opacity 75ms linear 150ms;
    visibility: visible;
    @media (min-width: 768px) {
      font-size: 20px;
      letter-spacing: 4.8px;
    }
  }
  h4 {
    text-align: left;
    font-family: var(--font-sans);
    font-size: 16px;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    font-weight: 300;
    opacity: 1;
  }
  p {
    font-family: var(--font-sans);
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.75;
    font-weight: 300;
    letter-spacing: normal;
    -webkit-font-smoothing: antialiased;
    text-indent: 0px;
    text-transform: none;
    font-style: normal;
    white-space: normal;
    text-shadow: inherit;
    opacity: 1;
    box-sizing: border-box;
    transition: opacity 75ms linear 150ms;
    visibility: visible;
  }
  .wide {
    letter-spacing: 3.5px;
  }
`

export default function Layout({ children }) {
  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favicon} />
        <title>RealtyMogul.com™ | Real Estate Crowdfunding & Investing</title>
      </Helmet>
      <GlobalStyle />
      <LocalStyle />
      {children}
    </React.Fragment>
  )
}