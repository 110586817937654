import * as React from "react";
import styled from "styled-components";
import { Button, BigNumber } from "rtmg-component-library";

import Layout from "../components/layout";
import Logo from "../images/logo.svg";
import heroBG from "../images/hero.png";
import boatBG from "../images/boat.png";
import Checkmark from "../images/Checkmark.svg";

const Header = styled.div`
  padding: 0 30px;
  height: 90px;
  background-color: rgb(255, 255, 255);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgb(0 0 0 / 15%) 0px 1px 0px 0px;
  box-sizing: border-box;
  img {
    width: 131px;
    height: 64px;
  }
  @media (min-width: 768px) {
    padding: 0 60px;
  }
`;

const Footer = styled(Header)`
  background-color: #202020;
  height: 100%;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  img {
    filter: invert(1);
    margin: 20px auto;
  }
  p {
    color: #fff;
    order: 2;
    font-size: 11px;
    display: block;
    width: 90%;
    padding-right: 0;
  }
  @media (min-width: 768px) {
    flex-direction: row-reverse;
    align-items: center;
    p {
      order: 1;
      width: 80%;
      padding-right: 50px;
    }
  }
`;

const WithBackground = styled.div`
  text-align: left;
  display: flex;
  color: #fff;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  ${({ background }) => `background: url(${background || "none"});`}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 95%;
  .hero-bottom {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  .foreground {
    padding: 50px 30px;
  }
  @media (min-width: 768px) {
    .foreground {
      padding: 60px 60px 50px;
    }
    background-position: 65%;
    .hero-bottom {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 45px;
    }
  }
`;

const Section = styled.div`
  padding: 40px;
  text-align: left;
  .learn-more {
    display: block;
    color: #176DB6;
    text-decoration: none;
    text-transform: uppercase;
    margin-top: 30px;
    font: normal normal 500 13px/22px var(--font-sans);
    letter-spacing: 0px;
  }
  @media (min-width: 768px) {
    padding: 60px;
  }
`;

const ButtonWrapper = styled.div`
  a {
    text-align: left;
    font: normal normal 500 12px/20px var(--font-sans);
    letter-spacing: 3.36px;
    color: #FFFFFF;
    text-transform: uppercase;
  }
  @media (min-width: 768px) {
    a {
      font: normal normal 500 14px/20px var(--font-sans);
    }
  }
  & :hover {
    a {
      background: #1D89FF;
      border: #1D89FF;
    }
  }
  &.header-button {
    width: 100px;
    height: 46px;
    a {
      font-size: 10px;
    }
  }
  &.top-button {
    width: 100%;
    a {
      font-size: 10px;
    }
    margin-top: 25px;
    @media (min-width: 768px) {
      a {
        font-size: 14px;
      }
      margin-top: 0;
      width: 55%;
    }
  }
  &.boost-button {
    margin-top: 25px;
    width: 100%; 
    @media (min-width: 768px) {
      margin-top: 70px;
      width: 45%;
    }
  }
  &.platform-button {
    width: 100%;
    margin-top: 70px;
    a {
      white-space: unset;
    }
    @media (min-width: 768px) {
      width: 70%;
    }
  }
  &.experience-button {
    width: 100%;
    a {
      box-sizing: border-box;
      white-space: unset;
      font-size: 10px;
    }
    @media (min-width: 768px) {
      width: 405px;
      a {
        font-size: 14px;
      }
    }
  }
  &.unlock-button {
    margin-top: 50px;
    width: 100%;
    a {
      white-space: unset;
    }
    @media (min-width: 768px) {
      width: 65%;
    }
  }
`;

const CalloutSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  @media (min-width: 768px) {  
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  };
  .down-arrow {
    margin: 30px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
      display: none;
    }
  }
`;

const CalloutWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 30px #0000001A;
  border-radius: 6px 6px 0px 0px;
  padding: 30px;
  box-sizing: border-box;
  &:not(:last-child) {
    @media (max-width: 768px) {
      margin-bottom: 30px;
    }
  }
  .arrow {
    display: none;
  }
  h3 {
    font: normal normal 400 32px/24px var(--font-sans);
    color: #176DB6;
    letter-spacing: 3.84px;
  }
  .divider {
    background: #A4C2D5;
    height: 1px;
    width: 30px;
    margin-top: 15px;
  }
  p {
    padding-right: 15px;
    font-size: 14px;
    margin-top: 20px;
    width: 95%;
    font: normal normal 400 13px/20px var(--font-sans);
    letter-spacing: 0px;
    color: #323232;
  }
  .sub-header {
    margin-top: 20px;
  }
  @media (min-width: 768px) {
    width: 30%;
    .arrow {
      display: block;
      transform: rotate(180deg);
      padding-left: 30px;
      svg {
        height: 24px;
        width: 24px;
      }
    }
  }
`;

const Callout = ({ header, subHeader, stat, over = false, label, last = false }) => (
  <CalloutWrapper>
    <h3>
      {header}
    </h3>
    <p className="sub-header">{subHeader}</p>
    <div className="divider" />
    <p>
      {over && <span>Over </span>}
      {stat && <><b>{stat}</b></>}
      {label && <span> {label}<sup>1</sup></span>}
    </p>
  </CalloutWrapper>
);

const CheckboxSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #FFF;
  margin-top: 25px;
  padding: 0 25px 25px;
  @media (min-width: 768px) {
    margin: 25px 0;
    padding: 0;
    flex-direction: row;
    background: transparent;
  }
`
const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: var(--font-sans);
  font-size: 12px;
  letter-spacing: 0.3px;
  .stats {
    display: flex;
    align-items: center;
    img {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }
  @media (min-width: 768px) {
    margin-top: 0;
    width: 20%;
    font-size: 12px;
  }
  .blue {
    color: #176DB6;
    text-align: left;
    font: normal normal 500 12px/28px var(--font-sans);
    letter-spacing: 0.3px;
    color: #176DB6;
  }
  .stats {
    margin-top: 15px;
    font-size: 14px;
    font-weight: 300;
  }
  .check {
    margin-right: 7px;
  }
`

const Checkbox = ({ label, first, second, third }) => (
  <CheckboxWrapper>
    {label && <div className="blue">{label}</div>}
    <div className="stats"><img src={Checkmark} alt="checkmark" />  {first}</div>
    <div className="stats"><img src={Checkmark} alt="checkmark" />  {second}</div>
    {third && <div className="stats"><img src={Checkmark} alt="checkmark" />  {third}</div>}
  </CheckboxWrapper>
)

const BigNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .right {
    width: 100%;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    .right {
      margin-left: 25px;
      width: 40%;
    }
  }
`

const IndexPage = () => {
  return (
    <Layout>
      <Header>
        <img src={Logo} alt="RealtyMogul" />
        <ButtonWrapper className="header-button">
          <Button href="https://www.realtymogul.com/user/register">JOIN</Button>
        </ButtonWrapper>
      </Header>
      <WithBackground background={heroBG}>
        <div className="foreground">
          <h4 style={{ margin: 0 }}>Institutional-Quality Investments</h4>
          <h1>Invest in real estate</h1>
          <h3 className="wide">BUILD THE FUTURE YOU WANT</h3>
          <div className="hero-bottom">
            <ButtonWrapper className="top-button">
              <Button href="https://www.realtymogul.com/investment-opportunities">
                ACCESS CURRENT OPPORTUNITIES
              </Button>
            </ButtonWrapper>
          </div>
        </div>
      </WithBackground>
      <Section style={{ color: "#202020" }}>
        <h2>Explore opportunities beyond stocks and bonds</h2>
        <p>
          Diversify with commercial real estate with the
          potential to generate income and growth.
        </p>
        <CalloutSection>
          <Callout
            header="ACCESS"
            subHeader="Private Market Offerings."
            stat="220,000+ Members"
            label="have joined the Platform."
          />
          <Callout
            header="INVEST"
            subHeader="In private placements and real estate investment trusts (REITS)."
            stat="$800 million invested"
            label="in real estate deals on the Platform."
            over
          />
          <Callout
            header="GAIN"
            subHeader="Exposure to more deals."
            stat="$4.0 billion"
            label="in real estate deals posted on the Platform."
            over
            last
          />
        </CalloutSection>
        <ButtonWrapper className="boost-button">
          <Button href="https://www.realtymogul.com/investment-opportunities">
            EXPLORE INVESTMENTS
          </Button>
        </ButtonWrapper>
      </Section>
      <Section style={{ color: "#202020", background: "#F2F2F2" }}>
        <h2>Your personal real estate investment pipeline</h2>
        <p style={{ marginTop: '25px' }}>
        Create an account, easily review and compare institutional-quality real estate deals and invest in the ones that meet your criteria. Property deals that have been featured on the Platform include:
        </p>
        <CheckboxSection>
          <Checkbox 
            first="Apartments"
            second="Office"
          />
          <Checkbox 
            first="Retail"
            second="Industrial"
          />
          <Checkbox 
            first="Mixed-use"
            second="Self-storage"
          />
          <Checkbox 
            first="Cold storage"
            second="Ground-up development"
          />
          <Checkbox 
            first="Mobile home parks"
            second="Student housing"
          />
        </CheckboxSection>
      </Section>
      <WithBackground
        background={boatBG}
        style={{
          textAlign: "left",
          justifyContent: "flex-start",
        }}
      >
        <div className="foreground">
          <h2><em>“RealtyMogul is the best one-stop shop for real estate crowdfunding.”<br/>–Motley Fool<sup style={{ fontSize: '12px', marginLeft: '5px', fontStyle: 'normal' }}>2</sup></em></h2>
          <ButtonWrapper className="platform-button">
            <Button href="https://www.realtymogul.com/investment-opportunities">
              INVEST IN REAL ESTATE, POWERED BY A PLATFORM
            </Button>
          </ButtonWrapper>
        </div>
      </WithBackground>
      <Section style={{ background: "#F2F2F2" }}>
        <h2>Access & transparency</h2>
        <p>
          Thoughtful, transparent information empowers you to make personal decisions in pursuit of your financial goals. 
        </p>
        <a
          className="learn-more"
          href="https://www.realtymogul.com/our-story"
        >{`LEARN MORE >`}</a>
      </Section>
      <Section>
        <h2>Get the help you need</h2>
        <p>
          Reach out and connect with a dedicated Investor Relations team member anytime you have questions about the Platform.
        </p>
        <a
          className="learn-more"
          href="https://www.realtymogul.com/knowledge-center/due-diligence"
        >{`LEARN MORE >`}</a>
      </Section>
      <Section style={{ background: "#F2F2F2", paddingBottom: '25px' }}>
        <h2 style={{ lineHeight: '1' }}>
          Invest in apartments, office, retail and other commercial properties
        </h2>
        <p style={{ marginTop: '25px' }}>
          RealtyMogul simplifies commercial real estate investing, giving
          Members access to commercial real estate opportunities with the
          potential to generate income.
        </p>
        <BigNumberContainer>
          <BigNumber number={4000000000} />
          <div className="right">
            <p style={{ fontSize: "11px", width: '70%', marginBottom: '10px' }}>
              OF PROPERTY VALUE OFFERED ON THE REALTYMOGUL PLATFORM.<sup>1</sup>
            </p>
            <ButtonWrapper className="experience-button">
              <Button href="https://www.realtymogul.com/investment-opportunities">
                EXPERIENCE SIMPLIFIED REAL ESTATE INVESTING
              </Button>
            </ButtonWrapper>
          </div>
        </BigNumberContainer>
      </Section>
      <Section style={{ background: "#000", color: "#FFF" }}>
        <h2>Diversify your investment portfolio with commercial real estate</h2>
        <p>
          It has always been our mission to give investors access to diverse, institutional-quality real estate opportunities, and we're proud of the diversity that has been hosted on the Platform.
        </p>
        <ButtonWrapper className="unlock-button">
          <Button href="https://www.realtymogul.com/investment-opportunities">
            UNLOCK EXCLUSIVE OPPORTUNITIES
          </Button>
        </ButtonWrapper>
      </Section>
      <Section>
        <p style={{ fontStyle: 'italic', fontSize: '12px', lineHeight: '24px' }}>1. Since inception in 2012 and as of December 31, 2021</p>
        <p style={{ fontStyle: 'italic', fontSize: '12px', lineHeight: '24px' }}>2. https://www.millionacres.com/real-estate-investing/crowdfunding/top-real-estate-crowdfunding-sites/</p>
      </Section>
      <Footer>
        <p style={{ textAlign: 'left' }}>
          * Information on this site, including information regarding
          targeted returns and investment performance, is provided by the
          sponsor of the investment opportunity and is subject to change.
          Forward-looking statements, hypothetical information or calculations,
          financial estimates and targeted returns are inherently uncertain.
          Such information should not be used as a primary basis for an
          investor’s decision to invest. Investment opportunities on the
          RealtyMogul Platform are speculative and involve substantial risk. You
          should not invest unless you can sustain the risk of loss of capital,
          including the risk of total loss of capital. Please see additional
          disclosures{" "}
          <a
            href="https://www.realtymogul.com/investment-disclosure"
            style={{ color: "#FFF" }}
          >
            here
          </a>
          .
        </p>
        <div style={{ width: "15%" }}>
          <img src={Logo} alt="RealtyMogul" style={{ display: "block" }} />
        </div>
      </Footer>
    </Layout>
  );
};

export default IndexPage;
